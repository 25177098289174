import { useCallback, useEffect, useState } from 'react'
// @mui
import Stack from '@mui/material/Stack'
import TextField from '@mui/material/TextField'
import InputAdornment from '@mui/material/InputAdornment'

// types
import { OptionType } from 'src/components/hook-form/rhf-autocomplete'
import { IBudgetTableFilters, IBudgetTableFilterValue } from 'src/types/budget'

// components
import Iconify from 'src/components/iconify'
import { useUserStore } from 'src/store/user'
import { SelectVendedores } from './filters/selectSellers'
import { SelectDateRange } from './filters/selectDateRange'
import { SelectStatus } from './filters/selectStatus'

// ----------------------------------------------------------------------

type Props = {
  filters: IBudgetTableFilters
  onFilters: (name: string, value: IBudgetTableFilterValue) => void
  roleOptions: OptionType[]
}

export default function UserTableToolbar({ filters, onFilters, roleOptions }: Props) {
  const [query, setQuery] = useState(filters.search)

  const { user } = useUserStore()

  const [debouncedQuery, setDebouncedQuery] = useState(filters.search)

  const handleFilterName = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    setQuery(event.target.value)
  }, [])

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedQuery(query)
    }, 1000)

    return () => {
      clearTimeout(handler)
    }
  }, [query])

  useEffect(() => {
    if (debouncedQuery !== filters.search) {
      onFilters('search', debouncedQuery)
      setQuery(debouncedQuery)
    }
  }, [debouncedQuery, filters.search, onFilters])

  return (
    <>
      <Stack
        spacing={2}
        alignItems={{ xs: 'flex-end', md: 'center' }}
        direction={{
          xs: 'column',
          md: 'row',
        }}
        sx={{
          p: 2.5,
          pr: { xs: 2.5, md: 1 },
        }}
      >
        <Stack direction='row' alignItems='center' spacing={2} flexGrow={1} sx={{ width: 1 }}>
          <SelectDateRange onFilters={onFilters} filters={filters} />
        </Stack>
        <Stack direction='row' alignItems='center' spacing={2} flexGrow={1} sx={{ width: 1 }}>
          <SelectStatus onFilters={onFilters} filters={filters} />
        </Stack>
        {user?.role === 'admin' && (
          <Stack direction='row' alignItems='center' spacing={2} flexGrow={1} sx={{ width: 1 }}>
            <SelectVendedores onFilters={onFilters} filters={filters} />
          </Stack>
        )}
        <Stack direction='row' alignItems='center' spacing={2} flexGrow={1} sx={{ width: 1 }}>
          <TextField
            fullWidth
            value={query}
            onChange={handleFilterName}
            placeholder='Buscar...'
            InputProps={{
              startAdornment: (
                <InputAdornment position='start'>
                  <Iconify icon='eva:search-fill' sx={{ color: 'text.disabled' }} />
                </InputAdornment>
              ),
            }}
          />

          {/*      <IconButton onClick={popover.onOpen}>
            <Iconify icon='eva:more-vertical-fill' />
          </IconButton> */}
        </Stack>
      </Stack>

      {/*   <CustomPopover
        open={popover.open}
        onClose={popover.onClose}
        arrow='right-top'
        sx={{ width: 140 }}
      >
        <MenuItem
          onClick={() => {
            popover.onClose()
          }}
        >
          <Iconify icon='solar:printer-minimalistic-bold' />
          Print
        </MenuItem>

        <MenuItem
          onClick={() => {
            popover.onClose()
          }}
        >
          <Iconify icon='solar:import-bold' />
          Import
        </MenuItem>

        <MenuItem
          onClick={() => {
            popover.onClose()
          }}
        >
          <Iconify icon='solar:export-bold' />
          Export
        </MenuItem>
      </CustomPopover> */}
    </>
  )
}
